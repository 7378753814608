<template>
  <div class="table-item">
    <div class="top-item" v-if="isIndexNumber(indexNumber - 1)">
      <div class="professional-name">
        {{ dataObj[indexNumber - 1].specialtyName || "--" }}
      </div>
      <div class="top-item-row2">
        专业代码{{ dataObj[indexNumber - 1].specialtyCode || "--" }} 计划{{
          dataObj[indexNumber - 1].planNum || 0
        }}人
      </div>
    </div>
    <div class="top-item" v-else>
      <div class="professional-name">--</div>
      <div class="top-item-row2">专业代码-- 计划--人</div>
    </div>
    <div class="bottom-item" v-if="isIndexNumber(indexNumber - 1)">
      <div class="item-box">{{ dataObj[indexNumber - 1].scoreLowest }}</div>
      <div class="item-box">
        {{ dataObj[indexNumber - 1].accumulateLowest }}
      </div>
      <div class="item-box">{{ dataObj[indexNumber - 1].admitNum }}</div>
    </div>
    <div class="bottom-item" v-else>
      <div class="item-box"></div>
      <div class="item-box"></div>
      <div class="item-box"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 240,
    },
    dataObj: {
      type: Array,
      default: () => [],
    },
    indexNumber: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataItem: [],
    };
  },
  methods: {
    isIndexNumber(val) {
      if (this.dataObj[val]) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dataObj: {
      handler(val) {
        this.dataItem = val;
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.table-item {
  font-size: 14px;
  width: inherit;
  .top-item {
    width: 100%;
    text-align: center;
    padding: 12px 10px;
    border-bottom: 1px solid #e5e5e5;
    color: #909399;
    .professional-name {
      color: #74d1be;
      overflow: hidden;
    }
  }
  .bottom-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #909399;
    .item-box {
      width: calc(100% / 3);
      // padding: 12px 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      word-wrap: break-word;
      &:nth-child(1) {
        border-right: 1px solid #e5e5e5;
      }
      &:nth-child(2) {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
}
</style>