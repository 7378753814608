<template>
  <div class="plan-detail">
    <div class="plan-title">{{ planTitle }}</div>
    <div class="volunteer-content">
      <el-table v-if="voPa != 2" :data="volunteerList" style="width: 100%" height="770" border>
        <el-table-column label="志愿" align="center" width="150">
          <template v-slot="scope">
            <div>
              <div>平行志愿{{ scope.$index + 1 }}</div>
              <div class="red-color" v-if="scope.row.type == 1">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
              <div class="yellow-color" v-if="scope.row.type == 2">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
              <div class="green-color" v-if="scope.row.type == 3">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date1" label="院校名称" align="center" width="150">
          <template v-slot="scope">
            <div v-if="voPa == 3">
              <div style="color: #24b699">
                {{ scope.row.specialtyOptions || "" }}
              </div>
              <div>{{ scope.row.specialtyNames || "" }}</div>
            </div>
            <div v-if="voPa == 1">
              <div style="color: #24b699">
                {{ scope.row.collegeName || "" }}
              </div>
              <div>{{ scope.row.collegeCode || "" }}</div>
            </div>
          </template>
        </el-table-column>

        <div v-for="index of 6" :key="index">
          <el-table-column align="center" width="240" class-name="more-header" :show-overflow-tooltip="true">
            <template slot="header" slot-scope="scope">
              <tableItem :indexNumber="index"></tableItem>
            </template>
            <template slot-scope="scope">
              <!-- {{ scope.$index }} -->
              <tableHeader :indexNumber="index" :dataObj="scope.row.specialtyList"></tableHeader>
            </template>
          </el-table-column>
        </div>
        <el-table-column label="排序" align="center" prop="sort " width="100">
          <template v-slot="scope">
            <el-input type="number" size="small" v-model="scope.row.sort" @change="tableRank(scope.row, scope.$index)">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template v-slot="scope">
            <div class="delete-btn" @click="deleteItem(scope.$index)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="voPa == 2" :data="volunteerList" style="width: 100%" height="770">
        <el-table-column label="志愿" align="center" width="150">
          <template v-slot="scope">
            <div>
              <div>平行志愿{{ scope.$index + 1 }}</div>
              <div class="red-color" v-if="scope.row.type == 1">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
              <div class="yellow-color" v-if="scope.row.type == 2">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
              <div class="green-color" v-if="scope.row.type == 3">
                录取率{{ scope.row.admitRate || 0 }}%
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="院校名称" align="center" width="150">
          <template v-slot="scope">
            <div>
              <div style="color: #24b699">
                {{ scope.row.collegeName || "" }}
              </div>
              <div>{{ scope.row.collegeCode || "" }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="专业名称" align="center" width="150" :show-overflow-tooltip="true">
          <template v-slot="scope">
            <div>
              <div style="color: #74d1be">
                {{ scope.row.specialtyName || "" }}
              </div>
              <!-- <div>{{ scope.row.specialtyCode || "" }}</div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="admissionYear" align="center" width="240">
          <el-table-column prop="scoreLowest" align="center" label="最低分" min-width="70"></el-table-column>
          <el-table-column prop="accumulateLowest" align="center" label="最低位次"></el-table-column>
          <el-table-column prop="admitNum" align="center" label="录取人数"></el-table-column>
        </el-table-column>
        <!-- <el-table-column :label="yearLast1 + '年'" align="center">
          <el-table-column
            prop="scoreYearLast"
            align="center"
            label="最低分"
            min-width="70"
          ></el-table-column>
          <el-table-column
            prop="accumulateYearLast"
            align="center"
            label="最低位次"
          ></el-table-column>
          <el-table-column
            prop="admitYearLast"
            align="center"
            label="录取人数"
          ></el-table-column>
        </el-table-column> -->
        <!-- <el-table-column :label="yearLast2 + '年'" align="center">
          <el-table-column
            prop="scoreYearOther"
            align="center"
            label="最低分"
            min-width="70"
          ></el-table-column>
          <el-table-column
            prop="accumulateYearOther"
            align="center"
            label="最低位次"
          ></el-table-column>
          <el-table-column
            prop="admitYearOther"
            align="center"
            label="录取人数"
          ></el-table-column>
        </el-table-column> -->
        <el-table-column label="排序" align="center" prop="sort " width="100">
          <template v-slot="scope">
            <el-input type="number" size="small" v-model="scope.row.sort" @change="tableRank(scope.row, scope.$index)">
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template v-slot="scope">
            <div class="delete-btn" @click="deleteItem(scope.$index)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="tip-mesage">
        注：志愿表中专业最低分、最低位次、录取人数仅展示上年数据，如需查看更多数据请前往查分数功能查看
      </div>
      <div class="save-btn" @click="savePlan(false)">保存志愿表</div>
    </div>
  </div>
</template>

<script>
import tableItem from "@/components/tableItem";
import tableHeader from "@/components/tableHeader";
import {
  saveAspiration,
  getDetail,
  submitAnalog,
  getOneProvince,
  getAccumulateByScore,
  updateUseState,
} from "@/api/IntelligentReporting";
import { mapState } from "vuex";
export default {
  components: {
    tableItem,
    tableHeader,
  },
  data() {
    return {
      planTitle: "个性化方案",
      volunteerList: [], //我的志愿
      voPa: null,
      currentYear: null,
      year: "",
      yearLast: "",
      yearLast1: "",
      yearLast2: "",
      aspirationAnalogId: "",
      intelligent: 0,
      isAKeyGeneration: false,
      student: {},
      admitBatch: "",
      accumulate: 1,
      admissionYear: ""
    };
  },
  mounted() {
    this.voPa = this.$store.state.voPa;
    this.volunteerList = this.$store.state.volunteerList || [];
    this.intelligent = this.$route.query.intelligent || 0;
    this.currentYear = new Date().getFullYear();
    this.isAKeyGeneration = this.$route.query.isAKeyGeneration;
    if (this.isAKeyGeneration) {
      this.aKeyGeneration();
    }
    this.aspirationAnalogId = this.$route.query.aspirationAnalogId;
    // 保存后查详情

    if (this.intelligent) {
      this.planTitle = "智能方案"
    }
    if (this.aspirationAnalogId) {
      this.getVolunteerDetail();
      if (this.$route.query.voPa) {
        this.voPa = this.$route.query.voPa
      }
    } else {
      // 智能方案
      if (this.intelligent) {
        this.getIntelligent();
      }
      if (this.volunteerList.length > 0) {
        let firstItem = this.volunteerList[0] || {};
        this.year = firstItem.year;
        this.yearLast = firstItem.yearLast
          ? firstItem.yearLast
          : this.currentYear;
        // console.log(this.yearLast, "222222222");
        this.yearLast = year - 1
        this.yearLast1 = this.yearLast ? this.yearLast - 1 : this.currentYear;
        this.yearLast2 = this.yearLast ? this.yearLast - 2 : this.currentYear;
      }
    }
  },
  computed: {
    ...mapState(["formObj", "studentInfo", "voNum"]),
  },
  methods: {
    // 首页开始推荐院校
    aKeyGeneration() {
      let student1 = sessionStorage.getItem("student") || {};
      this.student = JSON.parse(student1);
      // 获取批次
      getOneProvince({
        year: new Date().getFullYear(),
        areaProvinceId: this.student.province,
      }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          console.log(data, "data");
          let batchs = data.batchs || [];
          console.log(batchs, "batchs");
          for (let i = 0; i < batchs.length; i++) {
            if (batchs[i].strBatchType == "本科批") {
              this.admitBatch = batchs[i].batch;
              // this.voNum = batchs[i].voNum
              this.$store.commit("setVoNum", batchs[i].voNum);
              this.$store.commit("setVoPa", batchs[i].voPa);
              break;
            } else {
              this.admitBatch = batchs[0].batch;
              // this.voNum = batchs[0].voNum
              this.$store.commit("setVoNum", batchs[0].voNum);
              this.$store.commit("setVoPa", batchs[0].voPa);
            }
          }
          getAccumulateByScore({
            disciplineType: this.student.disciplineType,
            province: {
              name: this.student.examProvince,
              id: this.student.province,
            },
            year: new Date().getFullYear(),
            scoreMin: this.student.score,
          }).then((result) => {
            if (result.code == 1) {
              if (result.data) {
                this.accumulate = result.data;
              }
              let searchData = {
                admitBatch: this.admitBatch,
                accumulate: this.accumulate,
                disciplineType: this.student.disciplineType,
                examProvince: {
                  name: this.student.examProvince,
                  id: this.student.province,
                },
                totalScore: this.student.score,
              };
              //获取志愿
              submitAnalog(searchData).then((res1) => {
                if (res1.code == 1) {
                  this.volunteerList = res1.data || [];
                  this.volunteerList.sort(function (a, b) {
                    return a.sort - b.sort;
                  });
                  let firstItem = this.volunteerList[0] || {};
                  this.admissionYear = firstItem.admissionYear + "年"
                  // this.year = firstItem.year;
                  // this.yearLast = firstItem.yearLast
                  //   ? firstItem.yearLast
                  //   : this.currentYear;
                  // this.yearLast1 = this.yearLast
                  //   ? this.yearLast - 1
                  //   : this.currentYear;
                  // this.yearLast2 = this.yearLast
                  //   ? this.yearLast - 2
                  //   : this.currentYear;
                  this.savePlan(true);
                }
              });
            }
          });
        }
      });
    },
    // 排序
    tableRank(row, index) {
      let reg = /^[1-9]\d*$/;
      if (!reg.test(row.sort)) {
        row.sort = index + 1 + "";
        this.$message.warning("请输入大于1的正整数");
        return;
      }
      if (row.sort > this.volunteerList.length) {
        row.sort = this.volunteerList.length;
      }

      let sort = index + 1;
      if (row.sort == sort) {
        return;
      }
      if (+row.sort > sort) {
        this.volunteerList.splice(row.sort, 0, row);
        this.volunteerList.splice(index, 1);
      } else {
        this.volunteerList.splice(row.sort - 1, 0, row);
        this.volunteerList.splice(index + 1, 1);
      }

      this.volunteerList.forEach((item, index) => {
        item.sort = index + 1 + "";
        this.$set(this.volunteerList, index, item);
      });
    },

    // 保存
    savePlan(auto) {
      if (this.volunteerList.length > this.voNum) {
        this.$message.warning("当前批次最多可填报" + this.voNum + "个志愿");
        return;
      }
      let volunteerList = this.volunteerList.map((item) => {
        item.sort = +item.sort;
        return item;
      });
      let searchData = {};
      if (this.isAKeyGeneration) {
        searchData = {
          accumulate: this.accumulate,
          admitBatch: this.admitBatch,
          analogList: volunteerList,
          disciplineType: this.student.disciplineType,
          examProvince: {
            name: this.student.examProvince,
            id: this.student.province,
          },
          totalScore: this.student.score,
          voPa: this.voPa,
          fillType: 2,
        };
      } else {
        searchData = {
          accumulate: this.formObj.accumulate,
          admitBatch: this.formObj.admitBatchName,
          analogList: volunteerList,
          disciplineType: this.formObj.disciplineType,
          examProvince: {
            id: this.studentInfo.province,
            name: this.studentInfo.residenceProvince,
          },
          ignoreProvinces: this.formObj.examProvincesNot,
          provinces: this.formObj.examProvinces,
          specialtyNames: this.formObj.specialtyNames,
          totalScore: +this.formObj.totalScore,
          voPa: this.voPa,
          fillType: this.intelligent ? 2 : 1,
        };
      }
      saveAspiration(searchData).then((res) => {
        if (res.code == 1) {
          updateUseState({ type: 2 }).then((reslut) => {
            if (reslut.code == 1) {
              if (this.isAKeyGeneration) {
                if (!auto) {
                  this.$message.success("保存成功");
                }
              } else {
                let resData = res.data || {};
                let aspirationAnalogId = resData.aspirationAnalogId || "";
                if (!auto) {
                  this.$message.success("保存成功");
                  this.$store.commit("setVolunteerList", []);
                  this.$router.push({
                    path: "/intelligentReporting/planDetail",
                    query: {
                      aspirationAnalogId: aspirationAnalogId,
                    },
                  });
                }
              }
            }
          });
        }
      });
    },

    // 填报的志愿详情
    getVolunteerDetail() {
      getDetail({
        aspirationAnalogId: this.aspirationAnalogId,
      }).then((res) => {
        if (res.code == 1) {
          this.volunteerList = res.data || [];
          this.volunteerList.sort(function (a, b) {
            return a.sort - b.sort;
          });
          if (this.volunteerList.length > 0) {
            let firstItem = this.volunteerList[0] || {};
            this.admissionYear = firstItem.admissionYear + "年"
            this.year = firstItem.year;
            this.yearLast = this.year ? this.year - 1 : this.currentYear;
            console.log(this.yearLast, "222222222");
            this.yearLast1 = this.yearLast
              ? this.yearLast - 1
              : this.currentYear;
            this.yearLast2 = this.yearLast
              ? this.yearLast - 2
              : this.currentYear;
          }
        }
      });
    },

    // 删除
    deleteItem(index) {
      this.volunteerList.splice(index, 1);
      this.volunteerList.forEach((item, index) => {
        item.sort = index + 1 + "";
        this.$set(this.volunteerList, index, item);
      });
    },

    // 智能方案
    getIntelligent() {
      let searchData = {
        accumulate: this.formObj.accumulate,
        admitBatch: this.formObj.admitBatchName,
        disciplineType: this.formObj.disciplineType,
        examProvince: {
          id: this.studentInfo.province,
          name: this.studentInfo.residenceProvince,
        },
        ignoreProvinces: this.formObj.examProvincesNot,
        provinces: this.formObj.examProvinces,
        sex: this.studentInfo.sex,
        specialtyNames: this.formObj.specialtyNames,
        totalScore: +this.formObj.totalScore,
      };
      submitAnalog(searchData).then((res) => {
        if (res.code == 1) {
          // console.log(res, "222222222");
          this.volunteerList = res.data || [];
          this.volunteerList.sort(function (a, b) {
            return a.admitRate - b.admitRate;
          });
          this.volunteerList.forEach((item, index) => {
            item.sort = index + 1
          })

          let firstItem = this.volunteerList[0] || {};
          this.admissionYear = firstItem.admissionYear + "年"
          this.year = firstItem.year;
          this.yearLast = firstItem.yearLast
            ? firstItem.yearLast
            : this.currentYear;
          this.yearLast1 = this.yearLast ? this.yearLast - 1 : this.currentYear;
          this.yearLast2 = this.yearLast ? this.yearLast - 2 : this.currentYear;
          this.savePlan(true);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.plan-detail {
  max-width: 1450px;
  margin: 0 auto;

  .plan-title {
    text-align: center;
    font-size: 25px;
    color: #24b699;
    margin-bottom: 20px;
  }

  .volunteer-content {
    .delete-btn {
      width: 60px;
      height: 26px;
      margin: 0 auto;
      line-height: 26px;
      text-align: center;
      border-radius: 13px;
      color: #ff3333;
      border: 1px solid #ff3333;
      cursor: pointer;
    }

    .tip-mesage {
      color: #ff3333;
      margin: 20px 0;
    }

    .save-btn {
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 20px;
      width: 420px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #1ab394;
      color: #fff;
      cursor: pointer;
      border-radius: 50px;
    }
  }

  .red-color {
    color: #ff3333;
  }

  .yellow-color {
    color: #f59a23;
  }

  .green-color {
    color: #03bf16;
  }

  /deep/.el-table th.el-table__cell.more-header>.cell {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }

  /deep/.el-table .el-table__cell.more-header {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }

  /deep/.el-table .cell {
    padding: 0;
  }

  /deep/.el-table .el-table__cell:not(.more-header) {
    padding: 10px;
  }
}
</style>