<template>
  <div class="table-item">
    <div class="top-item">专业{{ indexNumber }}</div>
    <div class="bottom-item">
      <div class="item-box">最低分</div>
      <div class="item-box">最低位次</div>
      <div class="item-box">录取人数</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 240,
    },
    indexNumber: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="less" scoped>
.table-item {
  width: inherit;
  font-size: 14px;
  .top-item {
    width: 100%;
    text-align: center;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;
    color: #909399;
  }
  .bottom-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    color: #909399;
    .item-box {
      width: calc(100% / 3);
      padding: 12px 0;
      box-sizing: border-box;
      word-wrap: break-word;
      &:nth-child(1) {
        border-right: 1px solid #e5e5e5;
      }
      &:nth-child(2) {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
}
</style>